// import React from "react";
// import { BrowserRouter, Route, Switch, HashRouter } from "react-router-dom";
// import Dashboard from "./pages/Dashboard";
// import PrintInvitations from "./pages/PrintInvitations";

// const Routes = () => (
//   <BrowserRouter>
//     <Switch>
//       <Route path="/" component={Dashboard} />
//       <Route path="/print_invitations" component={PrintInvitations} />
//     </Switch>
//   </BrowserRouter>
// );

// export default Routes;

import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )}
        />

        <Route path="/login">
          <Login />
        </Route>

        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
