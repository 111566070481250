import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  notification,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getStationsReq } from "../../../services/requests";
import "./styles.css";

const ListStations = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [columns] = useState([
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: () => {},
      sortDirections: ["descend", "ascend"],
      width: "15%",
    },
    {
      title: "Endereço",
      dataIndex: "address",
      key: "address",
      render: (address, row) => {
        if (!address) return "----";

        var ad = "";
        if (address?.street) ad = `${address.street}, `;
        if (address?.number) ad += `${address.number}, `;
        if (address?.neighborhood) ad += `${address.neighborhood} - `;
        if (address?.city && address?.state)
          ad += `${address.city}/${address.state} - `;
        if (address?.zip_code) ad += `${address.zip_code}`;

        return <span>{ad}</span>;
      },
      width: "20%",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => (phone ? <span>{phone}</span> : "----"),
    },
    {
      title: "Empresa",
      dataIndex: "company",
      key: "company",
      render: (company) => <span>{company.name}</span>,
    },
    {
      title: "Potência",
      dataIndex: "power",
      key: "power",
      render: (power) => (power ? <span>{`${power}kW`}</span> : "----"),
      sorter: () => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Plugs",
      dataIndex: "plugs",
      key: "plugs",
      render: (plugs) => (
        <span>
          {plugs && plugs.length
            ? plugs.map((plug) => (
                <Tag style={{ margin: 2.5 }} color="processing" key={plug.id}>
                  {plug.name}
                </Tag>
              ))
            : "----"}
        </span>
      ),
      width: 100,
    },
    {
      title: "Data da Criação",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: () => {},
      sortDirections: ["descend", "ascend"],
      render: (date) => <span>{new Date(date).toLocaleString()}</span>,
    },
    {
      title: "Hor. de Func.",
      dataIndex: "businessHours",
      key: "businessHours",
      render: (businessHours, row) =>
        businessHours ? <span>{businessHours}</span> : "----",
      width: "10%",
    },
    {
      title: "Tipo de local",
      dataIndex: "localityType",
      key: "localityType",
      render: (localityType) => <span>{localityType.name}</span>,
    },
    {
      title: "Estc. Gratuito",
      dataIndex: "freeParking",
      key: "freeParking",
      render: (freeParking) => (
        <span>
          {typeof freeParking == "boolean"
            ? freeParking
              ? "SIM"
              : "NÃO"
            : "----"}
        </span>
      ),
    },
    {
      title: "Dados compartilhados",
      dataIndex: "sharedData",
      key: "sharedData",
      render: (sharedData) => <span>{sharedData ? "SIM" : "NÃO"}</span>,
    },
    // {
    //   title: "Ações",
    //   key: "actions",
    //   width: 100,
    //   render: (text, data) => (
    //     <Tooltip placement="top" title={"Deletar"}>
    //       <Popconfirm
    //         placement="top"
    //         title={"Deseja realmente DELETAR?"}
    //         okText="Sim"
    //         cancelText="Não"
    //         onConfirm={() => {}}
    //       >
    //         <Button shape="circle" size="small" ghost type="danger">
    //           <DeleteOutlined />
    //         </Button>
    //       </Popconfirm>
    //     </Tooltip>
    //   ),
    // },
  ]);
  const [stations, setStations] = useState([]);
  const [totalStations, setTotalStations] = useState(0);
  let initFilters = {
    limit: 10,
    offset: 0,
    orderBy: "id",
    sort: "ASC",
  };
  const [filters, setFilters] = useState(initFilters);

  let history = useHistory();

  useEffect(() => {
    async function getStations() {
      try {
        setLoadingTable(true);

        const { stations, count } = await getStationsReq(filters);

        setStations(stations);
        setTotalStations(count);
      } catch (error) {
        notification.error({
          message: "Ops..",
          description: "Erro ao buscar as estações!",
        });
      } finally {
        setLoadingTable(false);
      }
    }

    getStations();
  }, [filters]);

  function onChangeTable(pagination, _, sorter, __) {
    setFilters({
      ...filters,
      limit: pagination.pageSize,
      offset: pagination.pageSize * (pagination.current - 1),
      orderBy: sorter.order ? sorter.columnKey : "id",
      sort: sorter.order && sorter.order === "descend" ? "DESC" : "ASC",
    });
  }

  return (
    <Content style={{ padding: 15 }}>
      <Breadcrumb>
        <Breadcrumb.Item>Estações</Breadcrumb.Item>
        <Breadcrumb.Item>Listagem</Breadcrumb.Item>
      </Breadcrumb>
      <div className="filter">
        <Form
          name="filter"
          onFinish={(values) => {
            setFilters({ ...values, ...initFilters });
          }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item name="name">
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Input
                className="firstInput"
                placeholder="Pesquise por nome..."
              />
              <Button
                icon={<SearchOutlined />}
                className="buttonSearch"
                htmlType="submit"
              >
                Pesquisar
              </Button>
              <Button
                icon={<PlusOutlined />}
                className="buttonNew"
                type="primary"
                onClick={() => history.push("/dashboard/upload_stations")}
              >
                Upload
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <Table
        style={{ backgroundColor: "#FFF" }}
        loading={loadingTable}
        rowKey={(data) => data.id}
        dataSource={stations}
        columns={columns}
        pagination={{ showSizeChanger: true, total: totalStations }}
        showSorterTooltip={false}
        onChange={onChangeTable}
      />
    </Content>
  );
};

export default ListStations;
