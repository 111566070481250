import axios from "axios";
import firebase from "./firebaseConfig";

const api = axios.create({
  baseURL: "https://api.abve.org.br",
  // baseURL: "http://localhost:3333",
});

api.interceptors.request.use(async (config) => {
  const token = await firebase.auth().currentUser.getIdToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
