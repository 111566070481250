import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Card, Row, Col, notification } from 'antd';
import {
  UserOutlined,
  LockOutlined
} from "@ant-design/icons";
import firebase from "../../services/firebaseConfig";

class Login extends Component {

  state = {
    email: '',
    password: '',
    isSigninInProgress: false,
  }

  login = async (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    if (email && password) {

      this.setState({ isSigninInProgress: true });

      try {

        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

        await firebase.auth().signInWithEmailAndPassword(email, password);

        this.setState({ isSigninInProgress: false });

        notification.success({ message: 'Tudo certo', description: 'Login realizado com sucesso' });
        this.props.history.push('/dashboard');

        // if ((await firebase.auth().currentUser.getIdTokenResult()).claims.admin) {

        // } else {
        //   notification.error({ message: 'Ops...', description: 'Apenas administradores podem acessar esse sistema!' });
        // }


      } catch (error) {
        console.log(error)

        notification.error({ message: 'Algo deu errado!', description: 'Verifique suas credenciais' });
        this.setState({ isSigninInProgress: false });

      }

    }
  }

  render() {

    return (
      <div>
        <Row style={{ display: 'flex', justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
          <Col xs={20} sm={16} md={12} lg={8} xl={5}>
            <Card headStyle={{ textAlign: 'center', backgroundColor: '#001529', color: 'white' }} title="Fazer Login">
              <Form onSubmit={this.login}>
                <Form.Item>
                  <Input
                    prefix={<UserOutlined color="'rgba(0,0,0,.25)'" />}
                    size="large"
                    type="email"
                    placeholder="E-mail"
                    value={this.state.email}
                    onChange={(event) => this.setState({ email: event.target.value })}
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    prefix={<LockOutlined color="'rgba(0,0,0,.25)'" />}
                    size="large"
                    type="password"
                    placeholder="Senha"
                    value={this.state.password}
                    onChange={(event) => this.setState({ password: event.target.value })}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    onClick={this.login}
                    loading={this.state.isSigninInProgress}
                    style={{ width: '100%', backgroundColor: '#001529' }}
                    type="primary"
                  >
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
export default withRouter(Login);
