import { CopyOutlined } from "@ant-design/icons";
import { Col, Form, message, notification, Row, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import copy from "copy-to-clipboard";
import debounce from "lodash.debounce";
import React, { useState } from "react";
import {
  getCitiesReq,
  getNeighborhoodsReq,
  getStatesReq,
} from "../../../services/requests";
import "./styles.css";

const { Option, OptGroup } = Select;

const initList = {
  data: [],
  total: 0,
};

const ListLocalities = () => {
  const [form] = Form.useForm();

  const [states, setStates] = useState(initList);
  const [loadingStates, setLoadingStates] = useState(false);

  const [cities, setCities] = useState(initList);
  const [loadingCities, setLoadingCities] = useState(false);

  const [neighborhoods, setNeighborhoods] = useState(initList);
  const [loadingNeighborhoods, setLoadingNeighborhoods] = useState(false);

  async function getStates(name = "") {
    try {
      setLoadingStates(true);

      const states = await getStatesReq({ name });

      setStates(states);
    } catch (error) {
      notification.error({
        message: "Ops..",
        description: "Erro ao buscar os estados!",
      });
    } finally {
      setLoadingStates(false);
    }
  }

  async function getCities(name = "") {
    try {
      setLoadingCities(true);

      const cities = await getCitiesReq({
        name,
        stateId: form.getFieldValue("state"),
      });

      setCities(cities);
    } catch (error) {
      notification.error({
        message: "Ops..",
        description: "Erro ao buscar as cidades!",
      });
    } finally {
      setLoadingCities(false);
    }
  }

  async function getNeighborhoods(name = "") {
    try {
      setLoadingNeighborhoods(true);

      const neighborhoods = await getNeighborhoodsReq({
        name,
        cityId: form.getFieldValue("city"),
      });

      setNeighborhoods(neighborhoods);
    } catch (error) {
      notification.error({
        message: "Ops..",
        description: "Erro ao buscar os bairros!",
      });
    } finally {
      setLoadingNeighborhoods(false);
    }
  }

  const valueCity = form.getFieldValue("city");
  const valueNeighborhood = form.getFieldValue("neighborhood");

  return (
    <Content className="content">
      <Form form={form} name="locality" layout="vertical">
        {() => (
          <Row gutter={[40, 40]}>
            <Col xs={24} sm={24} lg={8}>
              <Form.Item name="state" label="Estado">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Selecione o estado"
                  onChange={(value) => {
                    form.setFieldsValue({
                      state: value,
                    });
                    form.resetFields(["city", "neighborhood"]);
                    setCities(initList);
                  }}
                  onFocus={() => getStates()}
                  onSearch={debounce(getStates, 500)}
                  loading={loadingStates}
                >
                  <OptGroup label={`Total: ${states.total}`}>
                    {states.data.map((state) => (
                      <Option key={state.id} value={state.id}>
                        {/* {state.name} */}
                        {`${state.name} (${state.abbreviation})`}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <Form.Item name="city" label="Cidade">
                <Select
                  disabled={!form.getFieldValue("state")}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Selecione a cidade"
                  onChange={(value) => {
                    form.setFieldsValue({
                      city: value,
                    });
                    form.resetFields(["neighborhood"]);
                    setNeighborhoods(initList);
                  }}
                  onFocus={() => getCities()}
                  onSearch={debounce(getCities, 500)}
                  loading={loadingCities}
                >
                  <OptGroup label={`Total: ${cities.total}`}>
                    {cities.data.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={8}>
              <Form.Item name="neighborhood" label="Bairro">
                <Select
                  disabled={!valueCity}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder="Selecione o bairro"
                  onChange={(value) => {
                    form.setFieldsValue({
                      neighborhood: value,
                    });
                  }}
                  onFocus={() => getNeighborhoods()}
                  onSearch={debounce(getNeighborhoods, 500)}
                  loading={loadingNeighborhoods}
                >
                  <OptGroup label={`Total: ${neighborhoods.total}`}>
                    {neighborhoods.data.map((neighborhood) => (
                      <Option key={neighborhood.id} value={neighborhood.id}>
                        {neighborhood.name}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      <Row>
        <Col span={24}>
          <div className="containerInfo">
            <span style={{ fontSize: 24 }}>
              Selecione os dados acima para ver o código da localidade:
            </span>

            <div
              onClick={
                valueNeighborhood
                  ? () => {
                      copy(valueNeighborhood);
                      message.info("Copiado com sucesso!");
                    }
                  : () => {}
              }
              className={`containerCode ${
                valueNeighborhood ? "cursorPointer" : ""
              }`}
            >
              {valueNeighborhood ? (
                <>
                  <CopyOutlined style={{ fontSize: 20, marginRight: 5 }} />
                  <span className="code">{valueNeighborhood}</span>
                </>
              ) : (
                <span className="code">----</span>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default ListLocalities;
