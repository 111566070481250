import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, message, notification, Row, Table, Upload } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { useHistory } from "react-router";
import * as XLSX from "xlsx";
import languageEncoding from "detect-file-encoding-and-language";
import { sendUploadReq } from "../../../services/requests";
import "./styles.css";

const UploadStations = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const history = useHistory();

  const [columns] = useState([
    {
      title: "Empresa",
      dataIndex: "company",
      key: "company",
      width: 100,
    },
    {
      title: "Tipo de Local",
      dataIndex: "localityType",
      key: "localityType",
      width: 125,
    },
    {
      title: "Dados Compartilhados",
      dataIndex: "sharedData",
      key: "sharedData",
      width: 200,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },

    {
      title: "CEP",
      dataIndex: "zipCode",
      key: "zipCode",
      width: 150,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Logradouro",
      dataIndex: "street",
      key: "street",
      width: 200,
    },
    {
      title: "Número",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Bairro",
      dataIndex: "neighborhood",
      key: "neighborhood",
    },
    {
      title: "Cidade",
      dataIndex: "city",
      key: "city",
      width: 200,
    },
    {
      title: "UF",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Horário de Func.",
      dataIndex: "businessHours",
      key: "businessHours",
      width: 200,
    },
    {
      title: "Plugs",
      dataIndex: "plugs",
      key: "plugs",
      width: 150,
    },
    {
      title: "Potência",
      dataIndex: "power",
      key: "power",
    },
    {
      title: "Estac. Grátis",
      dataIndex: "freeParking",
      key: "freeParking",
      width: 150,
    },
  ]);

  async function sendUpload() {
    try {
      setLoadingTable(true);

      await sendUploadReq(dataSource);

      setDataSource([]);

      notification.success({
        message: "Pronto",
        description: "Estações cadastradas",
      });
      setLoadingTable(false);
      history.push("/dashboard/list_stations");
    } catch (error) {
      setLoadingTable(false);
      const msg = error?.response?.data?.msg;
      notification.error({
        message: "Ops",
        description: msg || "Erro ao enviar dados",
      });
    }
  }

  async function readFile(file) {
    var f = file;
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "string" });

      // if (
      //   readedData.SheetNames.length !== 2 &&
      //   readedData.SheetNames[1] !== "Dados"
      // ) {
      //   message.error("Planilha com o formato inválido");
      //   setDataSource([]);
      //   return;
      // }

      const sheetName = readedData.SheetNames[0];
      const dados = readedData.Sheets[sheetName];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(dados, {
        header: 1,
        defval: "",
      });

      let listaFinal = [];

      dataParse.slice(1).forEach((linha) => {
        listaFinal.push({
          company: linha[0],
          localityType: linha[1],
          sharedData: linha[2],
          name: linha[3],
          phone: linha[4],
          zipCode: linha[5],
          latitude: linha[6],
          longitude: linha[7],
          street: linha[8],
          number: linha[9],
          neighborhood: linha[10],
          city: linha[11],
          state: linha[12],
          businessHours: linha[13],
          plugs: linha[14],
          power: linha[15],
          freeParking: linha[16],
        });
      });

      console.log(listaFinal);

      setDataSource(listaFinal);
    };

    var result = {encoding: "utf-8"};
    try {
      result = await languageEncoding(f);
    } catch (error) {}

    reader.readAsText(f, result.encoding || "utf-8");
  }

  return (
    <Content className="content">
      <Row gutter={[40, 40]}>
        <Col span={24}>
          <h2>Selecionar planilha</h2>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
            customRequest={({ onSuccess, file }) => onSuccess()}
            beforeUpload={(file) => {
              if (file.type !== "text/csv") {
                notification.error({
                  message: "Ops...",
                  description: "Só é permitido arquivos .csv",
                });
                return false;
              }

              readFile(file);
              return true;
            }}
            onChange={(info) => {
              if (!info.fileList.length) setDataSource([]);
              updateFileList(info.fileList.filter((file) => !!file.status));
            }}
            fileList={fileList}
          >
            <Button
              disabled={fileList.length === 1}
              style={{ width: "100%", height: 66, overflow: "hidden" }}
              size="large"
              icon={<UploadOutlined />}
            >
              <span>Clique ou arraste a planilha até aqui</span>
            </Button>
          </Upload>
        </Col>

        <Col span={24}>
          <h2>Preview</h2>
          <Table
            bordered
            scroll={{ x: 900 }}
            style={{ backgroundColor: "#FFF" }}
            loading={loadingTable}
            rowKey={(data) => data["name"] + Date.now()}
            dataSource={dataSource}
            columns={columns}
          />
        </Col>

        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={24}>
          <Row>
            <Button
              disabled={loadingTable}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={loadingTable || !dataSource.length}
              onClick={() => sendUpload()}
              type="primary"
              style={{ marginLeft: 25 }}
            >
              Enviar
            </Button>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default UploadStations;
