import React from "react";
import { ConfigProvider } from "antd";
import Routes from "./routes";

import ptBR from 'antd/es/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale("pt-br");

const App = () => (
  <ConfigProvider locale={ptBR}>
    <Routes />
  </ConfigProvider>
);

export default App;
