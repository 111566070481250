import firebase from "firebase/app";

import "firebase/analytics";

import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCkDqEE8lVe3nGietQkJ_7-SDxMjkq1uFk",
  authDomain: "abve-shared.firebaseapp.com",
  projectId: "abve-shared",
  storageBucket: "abve-shared.appspot.com",
  messagingSenderId: "227151413960",
  appId: "1:227151413960:web:1c2fcdaa683068005e9bfd",
  measurementId: "G-2SL59MXFF0"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
