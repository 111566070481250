import Icon, {
  ApiOutlined,
  BankOutlined,
  BarsOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ThunderboltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import "antd/dist/antd.css";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { Fragment, useEffect, useState } from "react";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import {
  CreateCompany,
  ListCompanies,
  ListLocalities,
  ListStations,
  UploadStations,
} from "..";
import logo from "../../assets/img/logo5.png";
import firebase from "../../services/firebaseConfig";
import CreateLocalityType from "../LocalityTypes/CreateLocalityType";
import ListLocalityType from "../LocalityTypes/ListLocalityType";
import ListPlugs from "../Plugs/ListPlugs";
import Home from "./Home";
import "./styles.css";
const { Header, Sider } = Layout;

export default function Dashboard({ match }) {
  const [collapsed, setCollapsed] = useState(false);
  const [username, setUsername] = useState("");
  const [foto, setFoto] = useState("");
  const history = useHistory();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logout = async () => {
    await firebase.auth().signOut();
    history.push("/");
  };

  let { path, url } = useRouteMatch();

  useEffect(() => {
    if (firebase.auth().currentUser.displayName) {
      setUsername(firebase.auth().currentUser.displayName);
    } else {
      setUsername(firebase.auth().currentUser.email);
    }
    setFoto(firebase.auth().currentUser.photoURL);
  }, []);

  const menu = (
    <Menu style={{ marginTop: 10 }}>
      <Menu.Item onClick={logout}>Sair</Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Link to={"/dashboard"}>
            <img
              className="img-logo"
              style={{ width: collapsed ? "100%" : "60%" }}
              alt="Logo"
              src={logo}
            />
            {/* <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
              <img
                className="img-logo"
                alt="Logo"
                src={logo}
              />
              {!collapsed &&
                <span style={{
                  color: '#FFF',
                  fontWeight: 'bold',
                  marginLeft: 10,
                  fontSize: 22,
                }}>Estações</span>
              }
            </div> */}
          </Link>
        </div>
        <Menu
          // defaultOpenKeys={["1"]}
          // defaultSelectedKeys={["1"]}
          theme="dark"
          mode="inline"
        >
          <SubMenu icon={<ThunderboltOutlined />} title="Estações">
            <Menu.Item key="1">
              <Link to={`${url}/list_stations`}>
                <span>Listagem</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to={`${url}/upload_stations`}>
                <span>Upload</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="3">
            <Link to={`${url}/list_plugs`}>
              <ApiOutlined />
              <span>Plugs</span>
            </Link>
          </Menu.Item>
          <SubMenu icon={<BankOutlined />} title="Empresas">
            <Menu.Item key="4">
              <Link to={`${url}/list_companies`}>
                <span>Listagem</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to={`${url}/create_company`}>
                <span>Novo</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu icon={<BarsOutlined />} title="Tipos de Localidade">
            <Menu.Item key="6">
              <Link to={`${url}/list_locality_types`}>
                <span>Listagem</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to={`${url}/create_locality_type`}>
                <span>Novo</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item key="6">
            <Link to={`${url}/list_localities`}>
              <EnvironmentOutlined />
              <span>Localidades</span>
            </Link>
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout>
        <Header
          style={{
            background: "#FFF",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            className="trigger"
            component={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
            onClick={toggle}
          />

          <div className="perfil">
            <Dropdown overlay={menu}>
              <span style={{ color: "#1890ff" }}>
                <Avatar icon={<UserOutlined />} src={foto} />
                <span className="perfilName" style={{ marginLeft: 8 }}>
                  {username || "Usuário"}
                </span>
              </span>
            </Dropdown>
          </div>
        </Header>
        <Fragment>
          <Switch>
            <Route exact path={path}>
              <Home />
            </Route>
            <Route path={`${url}/upload_stations`}>
              <UploadStations />
            </Route>
            <Route path={`${url}/list_stations`}>
              <ListStations />
            </Route>
            <Route path={`${url}/list_plugs`}>
              <ListPlugs />
            </Route>
            <Route path={`${url}/list_localities`}>
              <ListLocalities />
            </Route>
            <Route path={`${url}/list_companies`}>
              <ListCompanies />
            </Route>
            <Route path={`${url}/create_company`}>
              <CreateCompany />
            </Route>
            <Route path={`${url}/list_locality_types`}>
              <ListLocalityType />
            </Route>
            <Route path={`${url}/create_locality_type`}>
              <CreateLocalityType />
            </Route>
          </Switch>
        </Fragment>
      </Layout>
    </Layout>
  );
}
