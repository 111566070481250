import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  notification,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getLocalityTypesReq } from "../../../services/requests";
import "./styles.css";

const ListLocalityType = () => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [columns] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Tooltip placement="rightTop" title={"Copiar"}>
          <div
            className="copy-id"
            onClick={() => {
              copy(String(id));
              message.info("Copiado com sucesso!");
            }}
          >
            <span>{id}</span>
          </div>
        </Tooltip>
      ),
      sorter: () => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: () => {},
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Data da Criação",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: () => {},
      sortDirections: ["descend", "ascend"],
      render: (date) => <span>{new Date(date).toLocaleString()}</span>,
    },
  ]);
  const [localityTypes, setLocalityTypes] = useState([]);
  const [totalLocalityTypes, setTotalLocalityTypes] = useState(0);
  let initFilters = {
    limit: 10,
    offset: 0,
    orderBy: "id",
    sort: "ASC",
  };
  const [filters, setFilters] = useState(initFilters);

  const history = useHistory();

  useEffect(() => {
    async function getLocalityTypes() {
      try {
        setLoadingTable(true);

        const { localityTypes, count } = await getLocalityTypesReq(filters);

        setLocalityTypes(localityTypes);
        setTotalLocalityTypes(count);
      } catch (error) {
        notification.error({
          message: "Ops..",
          description: "Erro ao buscar os tipos de localidade!",
        });
      } finally {
        setLoadingTable(false);
      }
    }

    getLocalityTypes();
  }, [filters]);

  function onChangeTable(pagination, _, sorter, __) {
    setFilters({
      ...filters,
      limit: pagination.pageSize,
      offset: pagination.pageSize * (pagination.current - 1),
      orderBy: sorter.order ? sorter.columnKey : "id",
      sort: sorter.order && sorter.order === "descend" ? "DESC" : "ASC",
    });
  }

  return (
    <Content style={{ padding: 15 }}>
      <Breadcrumb>
        <Breadcrumb.Item>Tipos de Localidade</Breadcrumb.Item>
        <Breadcrumb.Item>Listagem</Breadcrumb.Item>
      </Breadcrumb>
      <div className="filter">
        <Form
          name="filter"
          onFinish={(values) => {
            setFilters({ ...values, ...initFilters });
          }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item name="name">
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Input
                className="firstInput"
                placeholder="Pesquise por nome..."
              />
              <Button
                icon={<SearchOutlined />}
                className="buttonSearch"
                htmlType="submit"
              >
                Pesquisar
              </Button>
              <Button
                icon={<PlusOutlined />}
                className="buttonNew"
                type="primary"
                onClick={() => history.push("create_locality_type")}
              >
                Novo
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <Table
        style={{ backgroundColor: "#FFF" }}
        loading={loadingTable}
        rowKey={(data) => data.id}
        dataSource={localityTypes}
        columns={columns}
        pagination={{ showSizeChanger: true, total: totalLocalityTypes }}
        showSorterTooltip={false}
        onChange={onChangeTable}
      />
    </Content>
  );
};

export default ListLocalityType;
