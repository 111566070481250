import { ProfileOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row, Steps } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { createCompanyReq } from "../../../services/requests";
import "./styles.css";

const { Step } = Steps;

const CreateCompany = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loadingSave, setLoadingSave] = useState(false);

  async function createCompany(values) {
    try {
      setLoadingSave(true);

      await createCompanyReq(values);

      notification.success({
        message: "Pronto!",
        description: "Empresa criada com sucesso!",
      });

      history.push("list_companies");
    } catch (error) {
      notification.error({
        message: "Ops..",
        description: "Erro ao criar empresa!",
      });
    } finally {
      setLoadingSave(false);
    }
  }

  return (
    <Content className="content">
      <Row>
        <Col span={4}>
          <Steps current={1} size="small" direction="vertical">
            <Step
              icon={<ProfileOutlined />}
              title="Empresas"
              description="Adicione uma empresa"
            />
          </Steps>
        </Col>

        <Col span={8}>
          <Form
            form={form}
            name="company"
            layout="vertical"
            onFinish={createCompany}
            wrapperCol={{ span: 24 }}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Nome"
                  rules={[
                    {
                      required: true,
                      message: "Campo obrigatório",
                    },
                  ]}
                >
                  <Input placeholder="Nome da empresa" />
                </Form.Item>
              </Col>
            </Row>
            <div className="footer">
              <Button loading={loadingSave} htmlType="submit" type="primary">
                Salvar
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Content>
  );
};

export default CreateCompany;
