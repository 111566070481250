import api from "./api";

export async function getStationsReq(params = {}) {
  try {
    const {
      data: { data: stations, count },
    } = await api.get("stations", { params });

    return { stations, count };
  } catch (error) {
    throw error;
  }
}

export async function getPlugsReq(params = {}) {
  try {
    const {
      data: { data: plugs, count },
    } = await api.get("plugs", { params });

    return { plugs, count };
  } catch (error) {
    throw error;
  }
}

export async function getCompaniesReq(params = {}) {
  try {
    const {
      data: { data: companies, count },
    } = await api.get("companies", { params });

    return { companies, count };
  } catch (error) {
    throw error;
  }
}

export async function createCompanyReq(data = {}) {
  try {
    await api.post("companies", data);

    return true;
  } catch (error) {
    throw error;
  }
}

export async function getLocalityTypesReq(params = {}) {
  try {
    const {
      data: { data: localityTypes, count },
    } = await api.get("locality_types", { params });

    return { localityTypes, count };
  } catch (error) {
    throw error;
  }
}

export async function createLocalityTypeReq(data = {}) {
  try {
    await api.post("locality_types", data);

    return true;
  } catch (error) {
    throw error;
  }
}

export async function getStatesReq(params = {}) {
  try {
    const {
      data: { data, count: total },
    } = await api.get("states", { params });

    return { data, total };
  } catch (error) {
    throw error;
  }
}

export async function getCitiesReq(params = {}) {
  try {
    const {
      data: { data, count: total },
    } = await api.get("cities", { params });

    return { data, total };
  } catch (error) {
    throw error;
  }
}

export async function getNeighborhoodsReq(params = {}) {
  try {
    const {
      data: { data, count: total },
    } = await api.get("neighborhoods", { params });

    return { data, total };
  } catch (error) {
    throw error;
  }
}

export async function sendUploadReq(data = {}) {
  try {
    await api.post("upload", data);

    return true;
  } catch (error) {
    throw error;
  }
}
