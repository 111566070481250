import {
  ApiOutlined,
  BankOutlined,
  BarsOutlined,
  FileAddOutlined,
  FormOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import "./styles.css";
const { Meta } = Card;

export default function Home() {
  let history = useHistory();
  let { url } = useRouteMatch();

  function navigate(route) {
    history.push(route);
  }

  return (
    <Content className="content">
      {/* <p className="title">Menus</p> */}
      <Row gutter={[40, 40]}>
        <Col xs={24} md={12} xl={8} xxl={8}>
          <Card
            // hoverable
            style={{ borderWidth: 1, borderColor: "#e8e8e8" }}
            actions={[
              <Tooltip placement="top" title={"Upload"}>
                <FormOutlined
                  onClick={() => navigate(`${url}/upload_stations`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={
                <UploadOutlined style={{ fontSize: 50, color: "blue" }} />
              }
              title="Upload de Estações"
              description="Fazer upload de novas estações"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={8} xxl={8}>
          <Card
            // hoverable
            style={{ borderWidth: 1, borderColor: "#e8e8e8" }}
            actions={[
              <Tooltip placement="top" title={"Listagem das estações"}>
                <UnorderedListOutlined
                  onClick={() => navigate(`${url}/list_stations`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={
                <ThunderboltOutlined style={{ fontSize: 50, color: "blue" }} />
              }
              title="Estações"
              description="Listagem de todas as estações"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={8} xxl={8}>
          <Card
            // hoverable
            style={{ borderWidth: 1, borderColor: "#e8e8e8" }}
            actions={[
              <Tooltip placement="top" title={"Listagem das empresas"}>
                <UnorderedListOutlined
                  onClick={() => navigate(`${url}/list_companies`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
              <Tooltip placement="top" title={"Criar uma nova empresa"}>
                <FileAddOutlined
                  onClick={() => navigate(`${url}/create_company`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={<BankOutlined style={{ fontSize: 50, color: "blue" }} />}
              title="Empresas"
              description="Listagem de todas as empresas"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={8} xxl={8}>
          <Card
            // hoverable
            style={{ borderWidth: 1, borderColor: "#e8e8e8" }}
            actions={[
              <Tooltip placement="top" title={"Listagem dos plugs"}>
                <UnorderedListOutlined
                  onClick={() => navigate(`${url}/list_plugs`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={<ApiOutlined style={{ fontSize: 50, color: "blue" }} />}
              title="Plugs"
              description="Listagem de todos os plugs"
            />
          </Card>
        </Col>
        <Col xs={24} md={12} xl={8} xxl={8}>
          <Card
            // hoverable
            style={{ borderWidth: 1, borderColor: "#e8e8e8" }}
            actions={[
              <Tooltip
                placement="top"
                title={"Listagem dos tipos de localidade"}
              >
                <UnorderedListOutlined
                  onClick={() => navigate(`${url}/list_locality_types`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
              <Tooltip
                placement="top"
                title={"Criar um novo tipo de localidade"}
              >
                <FileAddOutlined
                  onClick={() => navigate(`${url}/create_locality_type`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={<BarsOutlined style={{ fontSize: 50, color: "blue" }} />}
              title="Tipos de Localidades"
              description="Listagem de todas os tipo de localidade"
            />
          </Card>
        </Col>
        {/* <Col xs={24} md={12} xl={8} xxl={8}>
          <Card
            // hoverable
            style={{ borderWidth: 1, borderColor: "#e8e8e8" }}
            actions={[
              <Tooltip placement="top" title={"Listagem das localidades"}>
                <UnorderedListOutlined
                  onClick={() => navigate(`${url}/list_localities`)}
                  style={{ color: "#4a8cfe" }}
                />
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={
                <EnvironmentOutlined style={{ fontSize: 50, color: "blue" }} />
              }
              title="Localidades"
              description="Listagem de todas as localidades"
            />
          </Card>
        </Col> */}
      </Row>
    </Content>
  );
}
